// Responsive breakpoints
var smBreakpoint = 768;
var mdBreakpoint = 992;
var lgBreakPoint = 1200;

function initPageScroller() {
    function getNavHeight() {
      if ($(window).width() > 767) {
          return $('body > header > nav').height();
      } else {
        return 50;
      }
    }
    var navHeight = getNavHeight();

    $('a.page-scroll').on('click', function(event) {
        navHeight = getNavHeight();
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href').substring($anchor.attr('href').indexOf('#'))).offset().top - navHeight
        }, 1500);
        event.preventDefault();
    });
    if (location.hash) {
        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);
        var $anchor = $('body').find(location.hash);
        $('html, body').stop().animate({
            scrollTop: $($anchor).offset().top - navHeight
        }, 1500);
        if (event !== undefined) {
            event.preventDefault(this);
        }
    }
}
function initAnimation()  {
    $('.section[data-animation]').each(function() {

        $(this).find('.section-inner').addClass('animate-hidden').viewportChecker ({

            classToAdd: 'animate-visible animated '+$(this).data('animation'),
            offset: 200

        });

    });
}
function centerFlexCollection()  {
    $('.flexCollection').each(function() {
        $(this).css('height', $(this).parent().parent().height()+'px');
    });
}
$(document).ready(function() {
    initAnimation();
    initPageScroller();
    centerFlexCollection();
    openModalOnHash();

    /*
    if (typeof google !== "object") {
      setTimeout(initGoogleMaps, 300);
    }
    */

    $(document.body).on('click touchend','#swipebox-slider .current img', function(e) {
      return false;
    }).on('click touchend','#swipebox-slider .current', function(e) {
      $('#swipebox-close').trigger('click');
    });
    setTimeout(function() {
        centerFlexCollection();
    }, 1000);
    //Referenzen im neuen Fenster öffnen lassen
    $('.neos-nodetypes-image a').attr('target', '_blank');

});
$( window ).resize(function() {
    centerFlexCollection();
});
$(document).on('click','.navbar-collapse.in',function(e) {
    if( $(e.target).is('a') ) {
        $(this).collapse('hide');
    }
});

function openModalOnHash() {
    if(window.location.hash) {
        var hash = window.location.hash.substring(1);
        $('#'+hash).modal('show');
    }
}
